<template>
    <div>
        <input-code
            v-for="(code, index) in codeLength"
            :key="index"
            :ref="
                (el) => {
                    codes[index] = el;
                }
            "
            @input="
                (value) => {
                    validateCodeInput(value, index);
                }
            "
            @keydown-move="
                (direction) => {
                    focusDifferentInput(direction, index);
                }
            "
        />
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import InputCode from "../input/InputCode.vue";
export default defineComponent({
    name: "CodeInputRow",
    props: {
        codeLength: {
            type: Number,
            required: true,
        },
    },
    components: { InputCode },
    emits: ["change"],
    setup(props, ctx) {
        const codes = ref([]);
        const state = reactive({
            code: [],
        });
        /**
         * functions
         */
        function focusDifferentInput(direction, index) {
            if (direction == "left" && index != 0) {
                codes.value[index - 1].input.focus();
            } else if (direction == "right" && index != props.codeLength - 1) {
                codes.value[index + 1].input.focus();
            }
        }
        function validateCodeInput(value, index) {
            const valueString = value.toString();
            let componentCodes = state.code;

            if (!valueString) {
                // if input is empty and you press remove; remove previous code and focus
                if (componentCodes[index] == "" && index != 0) {
                    let previousInput = codes.value[index - 1].input;
                    previousInput.value = "";
                    previousInput.focus();
                    componentCodes[index - 1] = "";
                }
                componentCodes[index] = "";
            } else if (valueString.length == 1) {
                componentCodes[index] = valueString;
                // focus next input
                if (index != componentCodes.length - 1) {
                    let nextInput = codes.value[index + 1].input;
                    nextInput.focus();
                }
            } else if (valueString.length != componentCodes.length) {
                componentCodes[index] = valueString.slice(-1);
                let currentInput = codes.value[index].input;
                currentInput.value = valueString.slice(-1);
                // focus next input
                if (index != componentCodes.length - 1) {
                    let nextInput = codes.value[index + 1].input;
                    nextInput.focus();
                }
            } else {
                // the pastet item is the same size as all code inputs
                for (
                    let iteratator = 0;
                    iteratator < componentCodes.length;
                    iteratator++
                ) {
                    let input = codes.value[iteratator].input;
                    let currentValue = valueString.charAt(iteratator);
                    componentCodes[iteratator] = currentValue;
                    input.value = currentValue;
                }

                let lastInput = codes.value[componentCodes.length - 1].input;
                lastInput.focus();
            }
            const codeString = componentCodes.join("");

            ctx.emit("change", codeString);
        }

        onMounted(() => {
            for (let index = 0; index < props.codeLength; index++) {
                state.code.push("");
            }
        });

        return {
            codes,
            focusDifferentInput,
            validateCodeInput,
        };
    },
});
</script>

<style></style>
