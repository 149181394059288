<template>
    <div class="bg-white h-screen p-3">
        <div class=" relativ">
            <div
                class="absolute left-6 top-3"
                @click="$router.push({ name: 'Login' })"
            >
                <img
                    class="flex-shrink-0 w-24"
                    src="@/assets/enterData.svg"
                    alt="Logo"
                />
            </div>
            <div class="mt-28 flex flex-col items-center justify-start">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "AuthContainer",
});
</script>

<style></style>
