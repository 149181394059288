<template>
  <auth-container>
    <h1 class=" mb-3 text-center font-semibold font-roboto text-xlg text-black">
      {{ $t("two_fa") }}
    </h1>
    <h3 class="text-grey-fontgrey max-w-full w-96 text-center mb-7">
      {{ $t("two_fa_text") }}
      <span
        @click="RESEND_2FA_CODE"
        class="text-black border-b border-primary cursor-pointer hover:text-opacity-80 hover:border-opacity-80 transition-all "
        >{{ $t("resend_code") }}</span
      >
    </h3>

    <code-input-row
      :codeLength="5"
      class="flex flex-row justify-between w-96 max-w-full mb-7"
      @change="setTwoFACode"
      @keyup.enter="SEND_2FA_CODE()"
    />

    <input-checkbox
      class="mb-5 w-96"
      :name="'stay_logged_in'"
      :id="'stay_logged_in'"
      v-model="state.twoFactorItem.trust_browser"
      :label="$t('trust_browser')"
      :defaultColor="'grey-fontgrey'"
      :activeColor="'primary'"
      @change="
        state.twoFactorItem.trust_browser = !state.twoFactorItem.trust_browser
      "
    />
    <div class="flex flex-row w-96 ">
      <div class="w-1/2 pr-2">
        <custom-button
          @click="$router.push({ name: 'Login' })"
          class="h-11"
          :text="$t('cancel')"
          :bgColor="'bg-black'"
          :textColor="'text-white'"
        />
      </div>
      <div class="w-1/2 pl-2">
        <custom-button
          @click="SEND_2FA_CODE()"
          class="w-96 h-11"
          :text="$t('continue')"
          :bgColor="'bg-primary'"
          :textColor="'text-white'"
        />
      </div>
    </div>
  </auth-container>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import CustomButton from "../../components/input/CustomButton.vue";
import InputCheckbox from "../../components/input/InputCheckbox.vue";
import { AUTH_TYPES, STORE_TYPES } from "../../store/types";

import { useStore } from "vuex";
import AuthContainer from "./helpers/AuthContainer.vue";
import CodeInputRow from "../../components/helper/CodeInputRow.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "TwoFactorCode",
  components: { InputCheckbox, CustomButton, AuthContainer, CodeInputRow },
  setup() {
    const codes = ref([]);
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      twoFactorItem: {
        code: "",
        trust_browser: false,
      },
    });

    /**
     * functions
     */
    function setTwoFACode(code) {
      state.twoFactorItem.code = code;
    }
    function SEND_2FA_CODE() {
      let userData = {
        token: state.twoFactorItem.code,
        trust_browser: state.twoFactorItem.trust_browser,
        email: route.params.email,
        password: route.params.password,
      };

      store.dispatch(`${STORE_TYPES.AUTH}/${AUTH_TYPES.LOGIN}`, {
        user: userData,
      });
    }
    function RESEND_2FA_CODE() {
      store.dispatch(`${STORE_TYPES.AUTH}/${AUTH_TYPES.RESEND_2FA_CODE}`);
    }

    return {
      codes,
      state,
      setTwoFACode,
      SEND_2FA_CODE,
      RESEND_2FA_CODE,
    };
  },
});
</script>

<style></style>
