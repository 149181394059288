<template>
  <div
    class="code-input p-4 pb-3  border cursor-pointer flex items-center justify-center transition-all hover:border-primary"
    :class="isActive ? 'border-primary' : 'border-grey-light'"
  >
    <input
      ref="input"
      class="w-full h-full text-4xl text-black pl-1 pb-2 border-0 border-primary transition-all bg-transparent"
      :class="{ 'border-b': isActive }"
      @click="$refs.input.select()"
      :type="type"
      @input="$emit('input', $event.target.value)"
      @keydown.delete="validateDelete"
      @keydown.left="$emit('keydownMove', 'left')"
      @keydown.right="$emit('keydownMove', 'right')"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "InputCode",
  props: {
    type: {
      type: String,
      default: "text",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input", "keydownMove"],
  setup(props, ctx) {
    const input = ref();

    function validateDelete(event) {
      //if the input is already empty
      if (event.target.value == "") {
        ctx.emit("input", "");
      }
    }
    return {
      input,
      validateDelete,
    };
  },
});
</script>

<style lang="scss">
.code-input {
  width: 60px;
  height: 67px;
  border-radius: 10px;
  &:focus-within,
  &:focus-within input {
    @apply border-primary;
  }
}
</style>
